<template>
  <div class="box">
    <div class="d-flex flex-column" v-if="isLoading">
      <v-progress-circular
        v-if="isLoading"
        class="align-self-center"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>

    <div v-if="!isLoading">
      <div class="d-flex flex-column box-data-participant">
        <label class="title-component">Detail Pendaftar</label>
        <div class="d-flex main-box" style="margin-bottom: 10px">
          <v-img
            v-if="participant.user.profilePictureUrl"
            :src="`${baseImageUrl}/${participant.user.profilePictureUrl}`"
            class="team-picture"
          />

          <div class="d-flex flex-column">
            <div class="d-flex team-name-box" style="width: 100%">
              <div class="d-flex flex-column align-self-start">
                <label class="team-title">Nama</label>
                <label class="team-title" style="margin-bottom: 10px">{{
                  participant.user.fullName
                }}</label>
              </div>
              <v-spacer />
              <label class="team-date align-self-start">{{
                dateFormating(new Date(participant.created_at))
              }}</label>
            </div>
            <div class="d-flex" style="margin-bottom: 8px">
              <label class="team-wa align-self-center">{{
                participant ? participant.whatsappNumber : ""
              }}</label>
              <div
                class="box-chat align-self-center"
                @click="chat(participant.user.id)"
                style="cursor: pointer; margin-right: 10px"
              >
                <div class="d-flex">
                  <label class="align-self-center" style="cursor: pointer"
                    >CHAT DISINI</label
                  >
                </div>
              </div>
              <div
                class="box-wa align-self-center"
                v-if="participant.whatsappNumber"
                @click="callWhatsapp(participant.whatsappNumber)"
                style="cursor: pointer"
              >
                <div class="d-flex">
                  <label class="align-self-center" style="cursor: pointer"
                    >WHATSAPP</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <label class="team-title" style="margin-top: 24px"
          >Data Game Pendaftar</label
        >

        <div class="d-flex flex-column">
          <div class="d-flex teams-list" style="width: 100%">
            <v-avatar
              class="align-self-start"
              color="primary"
              size="48"
              style="margin-right: 24px"
            >
              <v-img
                v-if="participant.user.profilePictureUrl"
                :src="`${baseImageUrl}/${participant.user.profilePictureUrl}`"
              ></v-img>
            </v-avatar>
            <div class="d-flex flex-column" style="width: 100%">
              <div class="d-flex">
                <label
                  class="align-self-center"
                  style="color: #79797c; min-width: 100px"
                  >Nama</label
                >
                <label class="align-self-center" style="color: #474748">{{
                  participant.user.fullName
                }}</label>
              </div>
              <div class="d-flex">
                <label
                  class="align-self-center"
                  style="color: #79797c; min-width: 100px"
                  >ID</label
                >
                <label class="align-self-center" style="color: #474748">{{
                  participant.userGameId
                }}</label>
              </div>
              <div class="d-flex">
                <label
                  class="align-self-center"
                  style="color: #79797c; min-width: 100px"
                  >Nickname</label
                >
                <label class="align-self-center" style="color: #474748">{{
                  participant.nicknameGame || "kosong"
                }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  name: "PersonalRegistrationData",
  props: ["registrationId", "type", "tournamentId"],
  watch: {
    registrationId: function (newVal, oldVal) {
      // watch it
      this.fetchParticipant(newVal);
    },
  },
  components: {},
  data: () => ({
    id: 0,
    participantId: 0,
    baseImageUrl: process.env.VUE_APP_IMAGE_URL,
    isLoading: true,
    participant: {},
    tab: null,
    btnLoadingAcc: false,
    btnLoadingReject: false,
  }),
  methods: {
    doUpdate() {
      this.$emit("updateParent", "update");
    },
    callWhatsapp(number) {
      let wa =
        number.substring(0, 2) != 62
          ? `62${number.substring(0, 1) == 0 ? number.substring(1) : number}`
          : `${number.substring(0, 1) == 0 ? number.substring(1) : number}`;
      window.open(`https://wa.me/${wa}`, "_blank");
    },
    async chat(id) {
      let config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getToken}`,
        },
      };

      await axios
        .create({ withCredentials: false })
        .post(
          `/v1/user/${this.$store.getters.getId}/chat_list/add/${id}`,
          null,
          config
        );
      this.isLoadingChat = false;
      // window.location.replace(`/chat?id=${id}`);
      this.$router.push(`/chat?id=${id}`);
    },
    async fetchParticipant(registrationId) {
      this.participantId = this.registrationId;
      this.isLoading = true;
      this.id = this.tournamentId;
      let config = {};

      if (this.$store.getters.getToken) {
        config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        };
      }

      let response = await axios
        .create({ withCredentials: false })
        .get(
          `/v1/tournament/${this.tournamentId}/participant/${registrationId}`,
          config
        );
      this.participant = response.data.result;
      this.isLoading = false;
      // this.activeTournaments = response.result;
    },
  },
  mounted() {
    if (this.registrationId) {
      this.fetchParticipant(this.registrationId);
      // console.log(this.registrationId);
    }
  },
});
</script>

<style>
/* @import "./assets/css/global.css"; */
.box {
  background-color: #fafafa;
  padding: 24px;
  border-radius: 8px;
  letter-spacing: 0.15px;
}

.teams-list {
  padding: 16px;
}

.team-title {
  font-size: 16px;
  font-weight: 400;
  color: #474748;
  letter-spacing: 0.15px;
}

.team-sub-title {
  font-size: 12px;
  font-weight: 400;
  color: #79797c;
  letter-spacing: 0.15px;
  margin-bottom: 8px;
  margin-top: 16px;
}

.title-component {
  font-weight: 500;
  font-size: 20px;
  color: black;
  margin-bottom: 24px;
}

.team-picture {
  max-height: 64px;
  max-width: 64px;
  border-radius: 8px;
  margin-right: 24px;
}

.team-wa {
  font-weight: 400;
  font-size: 16px;
  color: #141414;
  margin-right: 8px;
}

.box-chat {
  background-color: #f8b32b;
  color: white;
  font-size: 12px;
  padding: 6px;
  border-radius: 100px;
  font-weight: 500;
  letter-spacing: 1.25px;
}

.box-wa {
  background-color: #31b057;
  color: white;
  font-size: 12px;
  padding: 6px;
  border-radius: 100px;
  font-weight: 500;
  letter-spacing: 1.25px;
}

.box-data-participant {
  overflow: auto;
  max-height: 600px;
}

@media (max-width: 576px) {
  .main-box {
    flex-direction: column;
  }
  .team-picture {
    margin-bottom: 20px;
  }

  .team-name-box {
    flex-direction: column;
  }
  .team-date {
    margin-bottom: 20px;
  }
}
</style>
