<template>
  <div>
    <loading-screen v-if="isLoading"></loading-screen>
    <v-row v-else class="pa-3">
      <v-col xs="12" sm="12" md="4">
        <v-btn
          class="float-right white--text"
          v-if="!tournaments.deleted_at"
          small
          color="red"
          @click="reportAction(tournaments.tournamentId, 'banned')"
        >
          BANNED
        </v-btn>
        <v-btn
          class="float-right"
          v-else
          small
          color="success"
          @click="reportAction(tournaments.tournamentId, 'unbanned')"
        >
          LEPAS BANNED
        </v-btn>
        <div class="d-flex">
          <div class="box-wifi d-flex">
            <label
              class="align-self-center"
              style="text-transform: capitalize"
              >{{ tournaments.mode }}</label
            >
          </div>

          <v-img
            :src="`${baseImageUrl}/${tournaments.game_type.imageUrl}`"
            max-height="24px"
            max-width="24px"
            class="align-self-center"
          ></v-img>

          <label class="align-self-center grey-label-detail">{{
            tournaments.game_type.gameTypeId != 18
              ? tournaments.game_type.name
              : tournaments.additionalGameName
          }}</label>
          <div class="dot-grey align-self-center"></div>
          <label class="align-self-center grey-label-detail"
            >Diposting {{ tournaments.postedTime }}</label
          >
        </div>

        <label class="detail-title">{{ tournaments.title }}</label>

        <div v-if="tournaments.prize != 0" class="box-prizepol">
          <div class="d-flex">
            <label class="prizepol-label">Prizepool</label>
            <v-spacer />
            <label class="prizepol-label-price"
              >Rp {{ formatPrice(tournaments.prize) }}</label
            >
          </div>
        </div>

        <div
          class="box-call"
          v-if="tournaments.userId != this.$store.getters.getId"
        >
          <div class="d-flex">
            <div class="d-flex flex-column align-self-center">
              <label style="font-weight: 400; font-size: 14px; color: #79797c"
                >Silakan hubungi pembuat acara untuk informasi
                selanjutnya</label
              >
            </div>
            <v-spacer />
            <v-btn
              color="#31B057"
              rounded
              v-bind="attrs"
              v-on="on"
              v-if="this.$store.getters.getToken"
              @click="addUserChatList(tournaments.user.id)"
              style="color: white"
              class="align-self-center btn-cancel-participation"
            >
              HUBUNGI</v-btn
            >
            <v-btn
              color="#31B057"
              rounded
              v-bind="attrs"
              v-on="on"
              v-else
              @click="toLogin"
              style="color: white"
              class="align-self-center btn-cancel-participation"
            >
              HUBUNGI</v-btn
            >
          </div>
        </div>

        <div class="d-flex flex-column">
          <div class="row">
            <div class="col-sm-3" style="font-weight: 700">
              Pendaftaran Dibuka
            </div>
            <div class="col-sm-9">
              {{
                newDateFormating(
                  new Date(parseInt(tournaments.openRegistrationDate))
                )
              }}
              -
              {{
                newDateFormating(
                  new Date(parseInt(tournaments.closedRegistrationDate))
                )
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3" style="font-weight: 700">
              Tournament Dimulai
            </div>
            <div class="col-sm-9">
              {{
                newDateFormating(
                  new Date(parseInt(tournaments.startTournament))
                )
              }}
              -
              {{
                newDateFormating(new Date(parseInt(tournaments.endTournament)))
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3" style="font-weight: 700">
              Biaya Pendaftaran
            </div>
            <div v-if="tournaments.registrationFee != 0" class="col-sm-9">
              Rp {{ formatPrice(tournaments.registrationFee) }}
            </div>
            <div v-else class="col-sm-9">Gratis</div>
          </div>
          <div class="row">
            <div class="col-sm-3" style="font-weight: 700">Slot</div>
            <div class="col-sm-9">
              {{ tournaments.slot }}
              {{ tournaments.registrantType == "personal" ? "Orang" : "Tim" }}
            </div>
          </div>
          <div v-if="tournaments.location_type" class="row">
            <div class="col-sm-3" style="font-weight: 700">Tipe Lokasi</div>
            <div class="col-sm-9">
              {{ tournaments.location_type.locationName }}
            </div>
          </div>
          <div v-if="tournaments.location_type" class="row">
            <div
              class="col-sm-3"
              v-if="tournaments.location_type"
              style="font-weight: 700"
            >
              Lokasi
            </div>
            <div
              style="text-transform: capitalize"
              class="col-sm-9"
              v-if="tournaments.location_type"
            >
              <p
                @click="
                  openMap(tournaments.address + ' ' + tournaments.regency.name)
                "
                style="color: #e89d08; cursor: pointer"
              >
                {{ tournaments.address }}
              </p>
              <p>
                Kelurahan {{ tournaments.village.name.toLowerCase() }},
                Kecamatan {{ tournaments.district.name.toLowerCase() }},
                {{ tournaments.regency.name.toLowerCase() }},
                {{ tournaments.province.name.toLowerCase() }}
              </p>
            </div>
          </div>
        </div>

        <div class="box-posting d-flex flex-column">
          <label style="font-size: 20px; font-weight: 500"
            >Diposting oleh</label
          >
          <div
            class="d-flex cursor"
            style="margin-top: 16px"
            @click="detailProfile(tournaments.user.id)"
          >
            <v-avatar
              class="align-self-center"
              color="primary"
              size="48"
              style="margin-right: 8px"
            >
              <v-img
                :src="`${baseImageUrl}/${tournaments.user.profilePictureUrl}`"
              ></v-img>
            </v-avatar>
            <div class="d-flex">
              <label class="align-self-center grey-label-detail cursor"
                >{{ tournaments.user.fullName }}
              </label>
              <i
                class="bx bx-badge-check align-self-center"
                style="color: #0356a1"
                v-if="tournaments.user.isVerified === 1"
              ></i>
            </div>
          </div>
        </div>

        <label
          style="
            font-size: 20px;
            font-weight: 500;
            margin-top: 32px;
            margin-bottom: 32px;
          "
          >Deskripsi</label
        >
        <p style="margin-bottom: 32px; white-space: pre-line">
          {{ tournaments.caption }}
        </p>
      </v-col>
      <v-col>
        <v-card class="pa-2" outlined tile>
          <div class="d-flex flex-column container">
            <div class="d-flex head">
              <label class="align-self-center title">Pendaftar</label>
            </div>

            <v-tabs v-model="tab">
              <v-tab>
                <div class="d-flex">
                  <label class="align-self-center tab-title">Permintaan</label>
                  <div class="count-box align-self-center">
                    ({{ totalPendingParticipant }})
                  </div>
                </div>
              </v-tab>
              <v-tab>
                <div class="d-flex">
                  <label class="align-self-center tab-title">Diterima</label>
                  <div class="count-box align-self-center">
                    ({{ totalAcceptedParticipant }})
                  </div>
                </div>
              </v-tab>
            </v-tabs>

            <div class="row">
              <div class="col-sm-6">
                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <div class="d-flex flex-column box-items">
                      <label v-if="participant.length == 0"
                        >Belum ada permintaan participant</label
                      >
                      <div
                        v-for="parti in participant"
                        :key="parti.registrationId"
                      >
                        <div
                          :class="`box-name ${
                            participantId == parti.registrationId
                              ? 'name-active'
                              : ''
                          }`"
                          @click="
                            handleClickParticipant(
                              parti.teamName,
                              parti.registrantType,
                              parti.registrationId
                            )
                          "
                        >
                          <div class="d-flex">
                            <v-avatar
                              class="align-self-start"
                              color="primary"
                              size="48"
                              style="margin-right: 24px"
                            >
                              <v-img
                                :src="`${baseImageUrl}/${parti.user.profilePictureUrl}`"
                              ></v-img>
                            </v-avatar>
                            <label class="align-self-start box-name-title">{{
                              parti.user.fullName
                            }}</label>
                            <v-spacer />
                            <label class="align-self-start box-name-title">{{
                              dateFormating(new Date(parti.created_at))
                            }}</label>
                          </div>
                        </div>
                      </div>

                      <v-btn
                        width="100%"
                        :loading="isLoadMorePending"
                        v-if="dataPending.next_page_url"
                        color="#f8b32b"
                        style="color: white; margin-top: 24px"
                        @click="handleLoadMorePending()"
                        >Load More</v-btn
                      >
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <div class="d-flex flex-column box-items">
                      <label v-if="participantAccepted.length == 0"
                        >Belum ada participant diterima</label
                      >
                      <div
                        v-for="acc in participantAccepted"
                        :key="acc.registrationId"
                      >
                        <div
                          :class="`box-name ${
                            participantId == acc.registrationId
                              ? 'name-active'
                              : ''
                          }`"
                          @click="
                            handleClickParticipant(
                              acc.teamName,
                              acc.registrantType,
                              acc.registrationId
                            )
                          "
                        >
                          <div class="d-flex">
                            <v-avatar
                              class="align-self-start"
                              color="primary"
                              size="48"
                              style="margin-right: 24px"
                            >
                              <v-img
                                :src="`${baseImageUrl}/${acc.user.profilePictureUrl}`"
                              ></v-img>
                            </v-avatar>
                            <label class="align-self-start box-name-title">{{
                              acc.user.fullName
                            }}</label>
                            <v-spacer />
                            <label class="align-self-start box-name-title">{{
                              dateFormating(new Date(acc.created_at))
                            }}</label>
                          </div>
                        </div>
                      </div>
                      <v-btn
                        width="100%"
                        :loading="isLoadMoreAccepted"
                        v-if="dataAccepted.next_page_url"
                        color="#f8b32b"
                        style="color: white; margin-top: 24px"
                        @click="handleLoadMoreAccepted()"
                        >Load More</v-btn
                      >
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </div>
              <div class="col-sm-6">
                <no-registration-data-component
                  v-if="!registrantType"
                ></no-registration-data-component>
                <team-registration-data
                  v-if="registrantType == 'team'"
                  style="margin-top: 40px"
                  :registrationId="participantId"
                  @updateParent="doUpdate()"
                  :tournamentId="this.$route.params.id"
                ></team-registration-data>
                <personal-registration-data
                  v-if="registrantType == 'personal'"
                  style="margin-top: 40px"
                  :registrationId="participantId"
                  :tournamentId="this.$route.params.id"
                  @updateParent="doUpdate()"
                >
                </personal-registration-data>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import LoadingScreen from "../components/LoadingScreen.vue";
import PersonalRegistrationData from "../components/PersonalRegistrationData.vue";
import NoRegistrationDataComponent from "../components/NoRegistrationDataComponent.vue";
import TeamRegistrationData from "../components/TeamRegistrationData.vue";

export default {
  name: "TournamentDetail",

  components: {
    TeamRegistrationData,
    LoadingScreen,
    PersonalRegistrationData,
    NoRegistrationDataComponent,
  },

  data() {
    return {
      tournaments: {},
      participant: [],
      participantAccepted: [],
      dataPending: {},
      dataAccepted: {},
      isLoadMorePending: false,
      isLoadMoreAccepted: false,
      pagePending: 1,
      pageAccepted: 1,
      totalAcceptedParticipant: 0,
      totalPendingParticipant: 0,
      isLoading: true,
      tab: null,
      baseImageUrl: process.env.VUE_APP_IMAGE_URL,
      registrantType: "",
      participantId: "",
    };
  },

  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },

    reportAction(id, action) {
      let doConfirm = window.confirm(`Sure to ${action} this?`);

      if (doConfirm) {
        let config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        };

        axios
          .create({ withCredentials: false })
          .get(
            `${process.env.VUE_APP_API_URL}/v2/admin/tournament/${this.$route.params.id}/banned_action/${action}`,
            config
          )
          .then((response) => {
            this.fetch();
            this.$toast.success(response.data.message);
          })
          .catch((error) => {
            this.$toast.warning(error.response.data.message || "Unauthorized");
            this.loading = false;
          });
      }
    },

    fetch() {
      const AuthStr = "Bearer ".concat(this.$store.getters.getToken);

      let url = `${process.env.VUE_APP_API_URL}/v2/admin/tournaments/${this.$route.params.id}`;

      axios
        .create({ withCredentials: false })
        .get(url, {
          headers: { Authorization: AuthStr },
        })
        .then((response) => {
          this.tournaments = response.data.result;

          this.fetchParticipant(this.pagePending);
          this.fetchParticipantAccepted(this.pageAccepted);
        })
        .catch((error) => {
          this.$toast.warning(error.response.data.message || "Unauthorized");
          this.loading = false;
          console.log(error);
        });
    },

    fetchParticipant(page) {
      const AuthStr = "Bearer ".concat(this.$store.getters.getToken);

      let url = `${process.env.VUE_APP_API_URL}/v1/tournament/${this.$route.params.id}/participant?status=pending&page=${page}`;

      axios
        .create({ withCredentials: false })
        .get(url, {
          headers: { Authorization: AuthStr },
        })
        .then((response) => {
          if (response.data.result.current_page == 1) {
            this.participant = response.data.result.data;
          } else {
            response.data.result.data.map((data) => {
              this.participant.push(data);
            });
          }
          this.totalAcceptedParticipant =
            response.data.tournament_registrant_data.total_accepted_registrant;
          this.totalPendingParticipant =
            response.data.tournament_registrant_data.total_pending_registrant;
          // this.activeTournaments = response.result;
          this.dataPending = response.data.result;
          this.isLoadMorePending = false;
        })
        .catch((error) => {
          this.$toast.warning(error.response.data.message || "Unauthorized");
          this.loading = false;
          console.log(error);
        });
    },

    fetchParticipantAccepted(page) {
      const AuthStr = "Bearer ".concat(this.$store.getters.getToken);

      let url = `${process.env.VUE_APP_API_URL}/v1/tournament/${this.$route.params.id}/participant?status=accepted&page=${page}`;

      axios
        .create({ withCredentials: false })
        .get(url, {
          headers: { Authorization: AuthStr },
        })
        .then((response) => {
          this.isLoading = false;
          if (response.data.result.current_page == 1) {
            this.participantAccepted = response.data.result.data;
          } else {
            response.data.result.data.map((data) => {
              this.participantAccepted.push(data);
            });
          }
          this.dataAccepted = response.data.result;
          this.isLoadMoreAccepted = false;
        })
        .catch((error) => {
          this.$toast.warning(error.response.data.message || "Unauthorized");
          this.loading = false;
          console.log(error);
        });
    },

    handleLoadMorePending() {
      this.isLoadMorePending = true;
      this.pagePending = this.pagePending + 1;
      this.fetchParticipant(this.pagePending);
    },
    handleLoadMoreAccepted() {
      this.isLoadMoreAccepted = true;
      this.pageAccepted = this.pageAccepted + 1;
      this.fetchParticipantAccepted(this.pageAccepted);
    },
    handleClickParticipant(teamName, registrantType, registrationId) {
      this.teamName = teamName;
      this.participantId = registrationId;
      this.registrantType = registrantType;
    },
    async addUserChatList(creatorUserId) {
      this.isLoadingChat = true;
      let config = {};
      if (this.$store.getters.getToken) {
        config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        };
      }

      await await axios
        .create({ withCredentials: false })
        .post(
          `/user/${this.$store.getters.getId}/chat_list/add/${creatorUserId}`,
          null,
          config
        );
      this.isLoadingChat = false;
      // window.location.replace(`/chat?id=${creatorUserId}`);
      this.$router.push(`/chat?id=${creatorUserId}`);
    },

    detailProfile(id) {
      this.$router.push(`/user/${id}`);
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>
<style>
.detail-tournaments-image {
  margin-bottom: 24px;
}
.detail-data {
  margin-top: 34px;
}
.box-icon {
  background-color: #f8b32b26;
  color: #f8b32b;
  padding: 10px;
  border-radius: 50%;
  font-size: 24px;
  margin-bottom: 8px;
}
.box-icon-disabled {
  background-color: rgba(248, 214, 144, 0.173);
  color: #fddb96;
  padding: 10px;
  border-radius: 50%;
  font-size: 24px;
  margin-bottom: 8px;
}
.label-box-action {
  color: #f8b32b;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1.25px;
}
.label-box-action-disabled {
  color: #fddb96;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1.25px;
}
.box-likes {
  background-color: #fafafa;
  color: #474748;
  font-size: 16px;
  font-weight: 400;
  padding: 16px;
  border-radius: 8px;
  margin-top: 24px;
}
.box-progress {
  margin-top: 40px;
  /* max-width: 368px; */
  margin-bottom: 24px;
}

.box-progress-label {
  margin-bottom: 8px;
  color: #0356a1;
  font-size: 16px;
}
.box-registration {
  background-color: rgba(248, 179, 43, 0.2);
  padding: 16px;
}
.total-detail {
  font-size: 48px;
  font-weight: 400;
  color: #141414;
}
.total-label {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #474748;
}
.box-wifi {
  padding: 8px 16px 8px 16px;
  border-radius: 5px;
  background-color: #fbd68d;
  color: #474748;
  font-weight: 500;
  margin-right: 40px;
}
.grey-label-detail {
  font-size: 16px;
  font-weight: 500;
  margin-right: 4px;
  margin-left: 10px;
  color: #606062;
}
.detail-title {
  font-size: 34px;
  text-transform: uppercase;
  color: #474748;
  letter-spacing: 0.25px;
  margin-top: 32px;
  margin-bottom: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.box-prizepol {
  background: linear-gradient(90.42deg, #0f8afa -4.97%, #1c68ac 109.69%);
  padding: 24px;
  border-radius: 5px;
  margin-bottom: 32px;
}

.prizepol-label {
  color: white;
  font-size: 20px;
  font-weight: 500;
}
.prizepol-label-price {
  color: white;
  font-size: 20px;
  font-weight: 700;
}
.box-posting {
  margin-top: 32px;
}
.box-comments {
  background: rgba(248, 179, 43, 0.15);
  padding: 16px;
  border-radius: 5px;
  margin-bottom: 16px;
}
.add-comments {
  background-color: white;
  width: 100%;
}
.box-cancel-participation {
  background-color: #fafafa;
  border-radius: 8px;
  padding: 16px;
}
.box-call {
  background-color: #cfe8fe;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 32px;
}
</style>
