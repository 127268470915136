<template>
  <div class="box">
    <div class="d-flex flex-column">
      <img
        class="align-self-center"
        :src="require('../assets/img/bean2.gif')"
        alt="loading..."
        width="100"
      />
      <label class="text-center align-self-center"
        >Silahkan Pilih Participant</label
      >
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "NoRegistrationData",
});
</script>

<style>
/* @import "./assets/css/global.css"; */
.box {
  background-color: #fafafa;
  padding: 24px;
  border-radius: 8px;
  letter-spacing: 0.15px;
}

.teams-list {
  padding: 16px;
}

.team-title {
  font-size: 16px;
  font-weight: 400;
  color: #474748;
  letter-spacing: 0.15px;
}

.team-sub-title {
  font-size: 12px;
  font-weight: 400;
  color: #79797c;
  letter-spacing: 0.15px;
  margin-bottom: 8px;
  margin-top: 16px;
}

.title-component {
  font-weight: 500;
  font-size: 20px;
  color: black;
  margin-bottom: 24px;
}

.team-picture {
  max-height: 64px;
  max-width: 64px;
  border-radius: 8px;
  margin-right: 24px;
}

.team-wa {
  font-weight: 400;
  font-size: 16px;
  color: #141414;
  margin-right: 8px;
}

.box-wa {
  background-color: #31b057;
  color: white;
  font-size: 12px;
  padding: 6px;
  border-radius: 100px;
  font-weight: 500;
  letter-spacing: 1.25px;
}

.box-data-participant {
  overflow: auto;
  max-height: 600px;
}

@media (max-width: 576px) {
  .main-box {
    flex-direction: column;
  }
  .team-picture {
    margin-bottom: 20px;
  }

  .team-name-box {
    flex-direction: column;
  }
  .team-date {
    margin-bottom: 20px;
  }
}
</style>
